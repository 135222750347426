export const AUD = 'AUD';
export const BRL = 'BRL';
export const CAD = 'CAD';
export const CNY = 'CNY';
export const CZK = 'CZK';
export const DKK = 'DKK';
export const EUR = 'EUR';
export const HKD = 'HKD';
export const HUF = 'HUF';
export const INR = 'INR';
export const ILS = 'ILS';
export const JPY = 'JPY';
export const MYR = 'MYR';
export const MXN = 'MXN';
export const TWD = 'TWD';
export const NZD = 'NZD';
export const NOK = 'NOK';
export const PHP = 'PHP';
export const PLN = 'PLN';
export const GBP = 'GBP';
export const RUB = 'RUB';
export const SGD = 'SGD';
export const SEK = 'SEK';
export const CHF = 'CHF';
export const THB = 'THB';
export const USD = 'USD';
