import variablesForChartInfo from '@/constants/variablesForChartInfo';

const chartInfo = {
  orders: {
    id: 1,
    icon: 'chart-star',
    iconClass: 'chart-item__icon_first',
    title: 'chart.titleFirst',
  },
  cliets: {
    id: 2,
    icon: 'chart-client',
    iconClass: 'chart-item__icon_second',
    title: 'chart.titleSecond',
  },
  balanse: {
    id: 3,
    icon: 'chart-people',
    iconClass: 'chart-item__icon_third',
    title: 'chart.titleThird',
  },
  payments: {
    id: 4,
    icon: 'chart-payments',
    iconClass: 'chart-item__icon_fourth',
    title: 'chart.titleFourth',
  },
  profit: {
    id: 5,
    title: 'chart.titleFifth',
    chartdata: {
      labels: [],
      datasets: [
        {
          label: '',
          borderWidth: variablesForChartInfo.borderWidth,
          fill: true,
          data: [],
          backgroundColor: variablesForChartInfo.datesetsBackgroundColor,
          borderColor: variablesForChartInfo.borderColor,
          pointRadius: variablesForChartInfo.pointRadius,
          pointBackgroundColor: variablesForChartInfo.pointBackgroundColor,
          pointBorderWidth: variablesForChartInfo.pointBorderWidth,
          pointBorderColor: variablesForChartInfo.pointBorderColor,
          pointHoverBorderWidth: variablesForChartInfo.pointHoverBorderWidth,
          pointHoverRadius: variablesForChartInfo.pointHoverRadius,
          pointHoverBorderColor: variablesForChartInfo.pointHoverBorderColor,
          pointHoverBackgroundColor: variablesForChartInfo.pointHoverBackgroundColor,
        },
      ],
    },
    options: {
      layout: {
        padding: {
          left: 15,
          right: 15,
          top: 30,
          bottom: 15,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      hover: {
        mode: 'nearest',
        intersect: false,
      },
      elements: {
        rectangle: {
          borderSkipped: 'left',
        },
      },
      tooltips: {
        enabled: true,
        // mode: 'nearest',
        intersect: false,
        position: 'custom',
        displayColors: false,
        backgroundColor: variablesForChartInfo.tooltipsBackground,
        cornerRadius: 3,
        titleFontSize: variablesForChartInfo.titleFontSize,
        titleFontFamily: variablesForChartInfo.titleFontFamily,
        titleFontColor: variablesForChartInfo.titleFontColor,
        titleAlign: 'center',
        bodyFontFamily: variablesForChartInfo.bodyFontFamily,
        bodyFontSize: variablesForChartInfo.bodyFontSize,
        bodyFontColor: variablesForChartInfo.bodyFontColor,
        bodyAlign: 'center',
        xPadding: 14,
        yPadding: 8,
        caretPadding: 20,
        caretSize: 0,
        callbacks: {
          label(tooltipItem) {
            return `$${Number(tooltipItem.yLabel)}`;
          },
        },
      },
      legend: {
        display: false,
      },
      scales: {
        xAxes: [{
          display: false,
          scaleLabel: {
            display: false,
          },
        }],
        yAxes: [{
          display: false,
        }],
      },
    },
  },
  income: {
    id: 6,
    title: 'chart.titleSixth',
    chartdata: {
      labels: [],
      datasets: [
        {
          label: '',
          borderWidth: variablesForChartInfo.borderWidth,
          fill: true,
          data: [],
          backgroundColor: variablesForChartInfo.datesetsBackgroundColor,
          borderColor: variablesForChartInfo.borderColor,
          pointRadius: variablesForChartInfo.pointRadius,
          pointBackgroundColor: variablesForChartInfo.pointBackgroundColor,
          pointBorderWidth: variablesForChartInfo.pointBorderWidth,
          pointBorderColor: variablesForChartInfo.pointBorderColor,
          pointHoverBorderWidth: variablesForChartInfo.pointHoverBorderWidth,
          pointHoverRadius: variablesForChartInfo.pointHoverRadius,
          pointHoverBorderColor: variablesForChartInfo.pointHoverBorderColor,
          pointHoverBackgroundColor: variablesForChartInfo.pointHoverBackgroundColor,
        },
      ],
    },
    options: {
      layout: {
        padding: {
          left: 15,
          right: 15,
          top: 30,
          bottom: 15,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      hover: {
        mode: 'nearest',
        intersect: false,
      },
      elements: {
        rectangle: {
          borderSkipped: 'left',
        },
      },
      tooltips: {
        enabled: true,
        // mode: 'nearest',
        intersect: false,
        position: 'custom',
        displayColors: false,
        backgroundColor: variablesForChartInfo.tooltipsBackground,
        cornerRadius: 3,
        titleFontSize: variablesForChartInfo.titleFontSize,
        titleFontFamily: variablesForChartInfo.titleFontFamily,
        titleFontColor: variablesForChartInfo.titleFontColor,
        titleAlign: 'center',
        bodyFontFamily: variablesForChartInfo.bodyFontFamily,
        bodyFontSize: variablesForChartInfo.bodyFontSize,
        bodyFontColor: variablesForChartInfo.bodyFontColor,
        bodyAlign: 'center',
        xPadding: 14,
        yPadding: 8,
        caretPadding: 20,
        caretSize: 0,
        callbacks: {
          label(tooltipItem) {
            return `$${Number(tooltipItem.yLabel)}`;
          },
        },
      },
      legend: {
        display: false,
      },
      scales: {
        xAxes: [{
          display: false,
          scaleLabel: {
            display: false,
          },
        }],
        yAxes: [{
          display: false,
        }],
      },
    },
  },
};

export default chartInfo;
