const MAIN_COLOR = '#fff';
const SECOND_COLOR = 'rgba(0, 0, 0, .8)';
const FONT_SIZE_BASE = 14;
const FONT_SIZE_SMALL = 12;
const VARIABLES_FOR_CHARTINFO = {
  pointBackgroundColor: 'transparent',
  pointBorderColor: 'transparent',
  datesetsBackgroundColor: 'rgba(39, 110, 241, 0.08)',
  pointHoverBorderWidth: 7,
  pointHoverRadius: 7,
  borderColor: '#276EF1',
  pointHoverBorderColor: '#276EF1',
  bodyFontSize: FONT_SIZE_SMALL,
  pointRadius: 2,
  pointBorderWidth: 2,
  titleFontSize: FONT_SIZE_BASE,
  borderWidth: 3,
  bodyFontFamily: 'Ubuntu, sans-serif',
  titleFontFamily: 'Ubuntu-medium, sans-serif',
  titleFontColor: MAIN_COLOR,
  bodyFontColor: MAIN_COLOR,
  pointHoverBackgroundColor: MAIN_COLOR,
  tooltipsBackground: SECOND_COLOR,
};

export default VARIABLES_FOR_CHARTINFO;
