import * as domains from '@/api/constants/domains';
import axios from 'axios';

export default {
  generateExcel(date) {
    const {
      start: dateStart = '',
      end: dateEnd = '',
      interval = '',
    } = date;
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      responseType: 'blob',
      baseURL: domains.SERVICES_API,
    });
    return instWithCred.get(
      `dashboards/excel-report?dateStart=${dateStart}&dateEnd=${dateEnd}&interval=${interval}`,
    );
  },
  getDashboard(date) {
    const {
      start: dateStart = '',
      end: dateEnd = '',
      interval = '',
      currency = 'USD',
    } = date;
    const token = JSON.parse(localStorage.getItem('token'));
    const { accessToken } = token;
    const instWithCred = axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: domains.SERVICES_API,
    });
    return instWithCred.get(
      `dashboards?dateStart=${dateStart}&dateEnd=${dateEnd}&interval=${interval}&currency=${currency}`,
    );
  },
};
