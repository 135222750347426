import {
  EUR,
  USD,
} from '@/constants/listFieldsCurrency';

const currencyMain = [
  {
    text: 'EUR',
    key: EUR,
  },
  {
    text: 'USD',
    key: USD,
  },
];
export default currencyMain;
