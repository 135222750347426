<template>
  <div class="admin-page-header">
    <h2 class="admin-page-header__page-title">
      {{ headerPageOptions.title }}
    </h2>
    <div
      class="admin-page-header-right"
    >
      <div
        v-if="headerPageOptions.calendar"
        class="admin-page-header__item admin-page-header__item_calendar"
      >
        <textInput
          :typeInput="'text'"
          :value.sync="formatDate"
          :showModal="true"
          :textCapitalize="true"
          :readonly="true"
          :class="{'active': datePicker}"
          :calendar="true"
          @handleClickIcon="showCalendar"
          @click.stop
        >
        </textInput>
        <transition name="fade-el">
          <datePickerModal
            v-if="datePicker"
            :left="true"
            :yearSettings="true"
            :modelConfig="modelConfig"
            :date.sync="filterQuery"
            :isRange="true"
            :datePicker.sync="datePicker"
            @closeModal="closeModal"
            @changeClickedFromDatePicker="changeClickedFromDatePicker"
            @changeFilterQuery="changeFilterQuery"
          >
          </datePickerModal>
        </transition>
      </div>
      <div
        v-if="headerPageOptions.search"
        class="admin-page-header__item admin-page-header__item_search"
      >
        <UBSearch
          :value.sync="headerPageOptions.search.value"
          @change="headerPageOptions.search.query"
        />
      </div>
      <div
        v-if="headerPageOptions.filter"
        class="admin-page-header__item admin-page-header__item_filter"
        :class="{'admin-page-header__item_width': headerPageOptions.filter}"
      >
        <selectWithKeyTemplate
          :settings="headerPageOptions.filter.options"
          @changeSelect="headerPageOptions.filter.change"
        />
      </div>
      <div
        v-if="headerPageOptions.filterSecond"
        class="admin-page-header__item admin-page-header__item_filter"
        :class="headerPageOptions.filterSecond.customClass"
      >
        <selectWithKeyTemplate
          :settings="headerPageOptions.filterSecond.options"
          @changeSelect="headerPageOptions.filterSecond.change"
        />
      </div>
      <div
        v-if="headerPageOptions.button"
        class="admin-page-header__item"
      >
        <button
          v-tooltip.bottom="headerPageOptions.button.tooltip"
          class="ub-btn ub-btn_primary"
          :class="headerPageOptions.button.customClass"
          @click="headerPageOptions.button.onClick"
        >
          <span class="ub-btn__icon">
            <i :class="headerPageOptions.button.icon"></i>
          </span>
          <span>{{ headerPageOptions.button.text }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import datePickerModal from '@/components/modals/datePickerModal';
import UBSearch from '@/components/UBTable/UBSearch/UBSearch';
import selectWithKeyTemplate from '@/components/fields/selectWithKeyTemplate';
import textInput from '@/components/fields/textInput';
import moment from 'moment';

export default {
  name: 'AdminPageHeader',
  components: {
    UBSearch,
    selectWithKeyTemplate,
    datePickerModal,
    textInput,
  },
  props: {
    clickedFromDatePicker: {
      type: Boolean,
      default: false,
    },
    headerPageOptions: {
      type: Object,
      default: () => {},
    },
    filterQueryData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      modelConfig: {
        start: {
          timeAdjust: '00:00:00',
        },
        end: {
          timeAdjust: '00:00:00',
        },
      },
      datePicker: false,
    };
  },
  computed: {
    filterQuery: {
      get() {
        return this.filterQueryData;
      },
      set(data) {
        this.$emit('update:filterQueryData', data);
      },
    },
    formatDate: {
      get() {
        const { start, end } = this.filterQuery;
        const startDate = moment(start).locale(this.language).format('DD MMM YYYY');
        const endDate = moment(end).locale(this.language).format('DD MMM YYYY');
        return `${startDate} - ${endDate}`;
      },
    },
    language() {
      return this.$i18n.locale;
    },
  },
  methods: {
    changeClickedFromDatePicker() {
      this.$emit('changeClickedFromDatePicker');
    },
    changeFilterQuery(data) {
      this.$emit('changeFilterQuery', data);
    },
    closeModal(data) {
      this.datePicker = data;
    },
    showCalendar() {
      this.datePicker = !this.datePicker;
    },
  },
};
</script>

<style scoped lang="scss">
  @import "../sass/variables";

  .ub-btn {
    &__icon {
      font-size: $font-size-h2;
    }

    &_download {
      padding: 0 8px;
    }
  }

  .admin-page-header {
    display: flex;
    justify-content: space-between;
    min-height: 40px;
    margin-bottom: 15px;
    align-items: center;

    &__page-title {
      font: $font-size-h1 $font-global-bold;
      user-select: none;
    }

    &-right {
      display: flex;
      margin-left: 30px;
    }

    &__item {

      &_calendar {
        position: relative;
      }

      &_currency {
        width: 100px;
      }

      & + & {
        margin-left: 8px;
      }

      & &_filter {
        width: 218px;
      }

      &_width {
        width: 350px;
      }

      &_search {
        width: 385px;
      }
    }
  }
</style>
