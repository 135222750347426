<script>
import Chart from 'chart.js';
import { Line, mixins } from 'vue-chartjs';

Chart.Tooltip.positioners.custom = (elements, position) => {
  if (!elements.length) {
    return false;
  }
  const offset = elements[0]._chart.width / 2 > position.x ? 30 : -30;
  return {
    x: position.x + offset,
    y: position.y,
  };
};
export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: {
    chartData: {
      type: [Array, Object],
      default: () => {},
    },
    options: {
      type: [Array, Object],
      default: () => {},
    },
  },
  mounted() {
    this.renderChart(
      this.chartData,
      this.options,
    );
  },
  methods: {
    update() {
      this.$data._chart.update();
    },
  },
};
</script>
