<template>
  <label class="ub-search">
    <input
      v-model="searchValue"
      class="ub-input ub-search__input"
      type="text"
      :placeholder="$t('global.search')"
      @input="emitValue"
    >
    <i class="ub-icon-loupe ub-search__icon" />
  </label>
</template>

<script>
import { debounce } from 'lodash';

export default {
  name: 'UBSearch',
  props: ['value'],
  computed: {
    emitValue() {
      return debounce(this.getValue, 500);
    },
    searchValue: {
      get() {
        return this.value;
      },
      set(data) {
        this.$emit('update:value', data);
      },
    },
  },
  methods: {
    getValue(e) {
      const val = e.target.value;
      this.$emit('change', val);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../sass/variables";

  .ub-search {
    position: relative;
    display: flex;
    align-items: center;

    &__icon {
      position: absolute;
      right: 13px;
      color: $color-silver-chalice;
      pointer-events: none;
    }

    &__input {
      width: 100%;
      height: 40px;
      padding: 12px 40px 12px 12px;
      border: 1px solid $color-gallery;
      transition: border-color .2s ease-in;
      border-radius: $borderRadius;

      &::placeholder {
        color: $color-silver-chalice;
        font-weight: 500;
        transition: 0.3s;
      }

      &:hover, &:focus {
        border-color: $color-dodger-blue;
      }

    }
  }

</style>
