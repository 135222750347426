<template>
  <transition
    name="fade-content"
    appear
    mode="out-in"
  >
    <section class="admin-reports-page">
      <adminPageHeader
        :headerPageOptions="headerPageOptions"
        :clickedFromDatePicker.sync="clickedFromDatePicker"
        :filterQueryData.sync="filterQuery"
        @changeClickedFromDatePicker="changeClickedFromDatePicker"
        @changeFilterQuery="changeFilterQuery"
      />
      <div class="chart">
        <div class="chart__list">
          <div class="chart-item">
            <div class="chart-item__left">
              <div
                class="chart-item__icon"
                :class="chartInfo.orders.iconClass"
              >
                <i :class="`ub-icon-${chartInfo.orders.icon}`"></i>
              </div>
            </div>
            <div class="chart-item__right">
              <div class="chart-item__value">
                <AnimatedNumber
                  :value="chartValues.totalOrders || 0"
                  :formatValue="formatToInt"
                >
                </AnimatedNumber>
              </div>
              <div class="chart-item__text">
                {{ $t(chartInfo.orders.title) }}
              </div>
            </div>
          </div>
          <div class="chart-item">
            <div class="chart-item__left">
              <div
                class="chart-item__icon"
                :class="chartInfo.cliets.iconClass"
              >
                <i :class="`ub-icon-${chartInfo.cliets.icon}`"></i>
              </div>
            </div>
            <div class="chart-item__right">
              <div class="chart-item__value">
                <AnimatedNumber
                  :value="chartValues.totalClients || 0"
                  :formatValue="formatToInt"
                >
                </AnimatedNumber>
              </div>
              <div class="chart-item__text">
                {{ $t(chartInfo.cliets.title) }}
              </div>
            </div>
          </div>
          <div class="chart-item">
            <div class="chart-item__left">
              <div
                class="chart-item__icon"
                :class="chartInfo.balanse.iconClass"
              >
                <i :class="`ub-icon-${chartInfo.balanse.icon}`"></i>
              </div>
            </div>
            <div class="chart-item__right">
              <div class="chart-item__value">
                {{ currentCurrency }}
                <AnimatedNumber
                  class="chart-item__price"
                  :value="chartValues.balance || 0"
                  :formatValue="formatToPrice"
                >
                </AnimatedNumber>
              </div>
              <div class="chart-item__text">
                {{ $t(chartInfo.balanse.title) }}
              </div>
            </div>
          </div>
          <div class="chart-item">
            <div class="chart-item__left">
              <div
                class="chart-item__icon"
                :class="chartInfo.payments.iconClass"
              >
                <i :class="`ub-icon-${chartInfo.payments.icon}`"></i>
              </div>
            </div>
            <div class="chart-item__right">
              <div class="chart-item__value">
                {{ currentCurrency }}
                <AnimatedNumber
                  class="chart-item__price"
                  :value="chartValues.totalPayoffs || 0"
                  :formatValue="formatToPrice"
                >
                </AnimatedNumber>
              </div>
              <div class="chart-item__text">
                {{ $t(chartInfo.payments.title) }}
              </div>
            </div>
          </div>
        </div>
        <div class="chart-info">
          <div class="chart-info__left">
            <div class="chart-info__map">
              <MapChart
                :countryData="countryData"
                highColor="#92B8FF"
                lowColor="#C5D9FF"
                countryStrokeColor="#ffffff"
                defaultCountryFillColor="#E8EFFF"
                :language="language"
              />
            </div>
          </div>
          <div class="chart-info__right">
            <div
              class="chart-line"
            >
              <div class="chart-line__text">
                <div class="chart-line__title">
                  {{ currentCurrency }}
                  <AnimatedNumber
                    class="chart-item__price"
                    :value="income"
                    :formatValue="formatToPrice"
                  >
                  </AnimatedNumber>
                </div>
                <div class="chart-line__value">
                  {{ $t(chartInfo.profit.title) }}
                </div>
              </div>
              <lineChart
                ref="incomeChart"
                :chartData="chartInfo.income.chartdata"
                :options="chartInfo.income.options"
              />
            </div>
            <div
              class="chart-line"
            >
              <div class="chart-line__text">
                <div class="chart-line__title">
                  {{ currentCurrency }}
                  <AnimatedNumber
                    class="chart-item__price"
                    :value="profit"
                    :formatValue="formatToPrice"
                  >
                  </AnimatedNumber>
                </div>
                <div class="chart-line__value">
                  {{ $t(chartInfo.income.title) }}
                </div>
              </div>
              <lineChart
                ref="profitChart"
                :chartData="chartInfo.profit.chartdata"
                :options="chartInfo.profit.options"
              />
            </div>
            <a
              v-show="false"
              ref="downloadExcel"
              href=""
            >
            </a>
          </div>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
import adminPageHeader from '@/components/adminPageHeader';
import chartInfo from '@/constants/chartInfo';
import moment from 'moment';
import lineChart from '@/components/chart/lineChart';
import dashboardApi from '@/api/dashboard/dashboardApi';
import AnimatedNumber from 'animated-number-vue';
import currencyMain from '@/constants/currencyMain';
import queryString from 'query-string';
import MapChart from 'vue-map-chart-update';

export default {
  name: 'Reports',
  components: {
    adminPageHeader,
    lineChart,
    AnimatedNumber,
    MapChart,
  },
  data() {
    return {
      countryData: {},
      clickedFromDatePicker: false,
      filterQuery: {
        start: new Date(new Date().setDate(new Date().getDate() - 31)),
        end: new Date(new Date().setDate(new Date().getDate())),
        interval: '1d',
        currency: 'USD',
      },
      curDate: '',
      prevDate: '',
      chartInfo,
      chartValues: {},
      currencyMain,
      interval: '',
    };
  },
  computed: {
    currentCurrency() {
      const { currency } = this.filterQuery;
      if (currency === 'EUR') {
        return '€';
      }
      return '$';
    },
    labelsProfit: {
      get() {
        return this.chartInfo.profit.chartdata.labels;
      },
      set(data) {
        this.chartInfo.profit.chartdata.labels = data;
      },
    },
    labelsIncome: {
      get() {
        return this.chartInfo.income.chartdata.labels;
      },
      set(data) {
        this.chartInfo.income.chartdata.labels = data;
      },
    },
    dataProfit: {
      get() {
        return this.chartInfo.profit.chartdata.datasets[0].data;
      },
      set(data) {
        this.chartInfo.profit.chartdata.datasets[0].data = data;
      },
    },
    dataIncome: {
      get() {
        return this.chartInfo.income.chartdata.datasets[0].data;
      },
      set(data) {
        this.chartInfo.income.chartdata.datasets[0].data = data;
      },
    },
    income() {
      const { income = [] } = this.chartValues;
      if (income.length) {
        return income
          .filter((item) => item._id)
          .reduce((acc, obj) => acc + +obj.total, 0);
      }
      return 0;
    },
    profit() {
      const { profit = [] } = this.chartValues;
      if (profit.length) {
        return profit
          .filter((item) => item._id)
          .reduce((acc, obj) => acc + +obj.total, 0);
      }
      return 0;
    },
    headerPageOptions() {
      return {
        title: this.$t('reportsPage.title'),
        calendar: true,
        filterSecond: {
          options: this.currencySelectSettings,
          change: this.changeCurrency,
          customClass: 'admin-page-header__item_currency',
        },
        button: {
          text: '',
          customClass: 'ub-btn_download',
          icon: 'ub-icon-download',
          tooltip: 'Export To Excel',
          onClick: this.exportToExcel,
        },
      };
    },
    currencySelectSettings() {
      return {
        optionList: this.currencyMain,
        placeholderText: this.$t('orderPage.placeholderSelect'),
        selectedKey: this.filterQuery.currency,
        disable: false,
        toTop: false,
        tabindex: 1,
        readOnly: true,
      };
    },
    language() {
      return this.$i18n.locale;
    },
  },
  beforeMount() {
    const { start: startDate, end: endDate } = this.filterQuery;
    const { location: { search } } = window;
    const parsed = queryString.parse(search);
    const {
      currency = 'USD',
      start = startDate,
      end = endDate,
    } = parsed;
    this.filterQuery = {
      ...this.filterQuery,
      currency,
      start,
      end,
    };
    this.addParameterInURL();
  },
  methods: {
    toUTCOffset(date) {
      const dateUTCOffset = !this.clickedFromDatePicker
        ? moment(date).utcOffset(0)
        : moment(date).add(1, 'days').utcOffset(0);
      dateUTCOffset.set({
        hour: 0, minute: 0, second: 0, millisecond: 0,
      });
      return dateUTCOffset;
    },
    addParameterInURL() {
      const {
        currency,
        start: startDate,
        end: endDate,
      } = this.filterQuery;
      const start = moment(this.toUTCOffset(startDate)).toISOString();
      const end = moment(this.toUTCOffset(endDate)).add(1, 'days').toISOString();
      const firstDate = moment(startDate);
      const lastDate = moment(endDate);
      const diffDays = lastDate.diff(firstDate, 'days');
      let interval = '';
      if (diffDays <= 30) {
        interval = '1d';
      } if (diffDays > 30 && diffDays <= 365) {
        interval = '1M';
      } if (diffDays > 365) {
        interval = '1y';
      }
      const date = {
        start,
        end,
        interval,
        currency,
      };
      this.changeDate(date);
      this.$router.push({
        query: {
          currency,
          start,
          end,
        },
      }).catch(() => {});
    },
    changeClickedFromDatePicker() {
      this.clickedFromDatePicker = true;
    },
    changeFilterQuery(data) {
      // diff days
      const { start, end } = data;
      this.filterQuery = {
        ...this.filterQuery,
        start,
        end,
      };
      this.addParameterInURL();
    },
    changeCurrency(currency) {
      this.filterQuery = {
        ...this.filterQuery,
        currency,
      };
      this.addParameterInURL();
    },
    formatToPrice(value) {
      return value.toFixed(2);
    },
    formatToInt(value) {
      return value.toFixed(0);
    },
    addValuesInGraphics(filterArr, interval, labels, date, addPrevDate) {
      filterArr
        .forEach((date) => {
          const converFromNumberToDate = new Date(+date._id);
          switch (interval) {
          case '1d':
            this.curDate = moment(converFromNumberToDate).locale(this.language).format('D MMMM');
            if (addPrevDate) {
              this.prevDate = moment(converFromNumberToDate).locale(this.language).add(-1, 'days').format('D MMMM');
            }
            break;
          case '1M':
            this.curDate = moment(converFromNumberToDate).locale(this.language).format('MMMM');
            if (addPrevDate) {
              this.prevDate = moment(converFromNumberToDate).add(-1, 'month').locale(this.language).format('MMMM');
            }
            break;
          case '1y':
            this.curDate = moment(converFromNumberToDate).locale(this.language).format('YYYY');
            if (addPrevDate) {
              this.prevDate = moment(converFromNumberToDate).add(-1, 'year').locale(this.language).format('YYYY');
            }
            break;
          default:
            break;
          }
          if (addPrevDate) {
            labels.push(this.prevDate, this.curDate);
          } else {
            labels.push(this.curDate);
          }
        });
      filterArr
        .forEach((item) => {
          const total = item.total.toFixed(2);
          if (addPrevDate) {
            date.push(0, total);
          } else {
            date.push(total);
          }
        });
      this.$nextTick(() => {
        this.$refs.incomeChart.update();
        this.$refs.profitChart.update();
      });
    },
    changeDate(date) {
      this.$nextTick(() => {
        this.labelsProfit = [];
        this.labelsIncome = [];
        this.dataProfit = [];
        this.dataIncome = [];
      });
      dashboardApi.getDashboard(date)
        .then((resp) => {
          const { countryPartners = [] } = resp.data;
          this.countryData = countryPartners.reduce((acc, country) => {
            country.partners.map((item) => delete item._id);
            acc[country.code.toUpperCase()] = country.partners;
            return acc;
          }, {});
          const { interval } = date;
          this.chartValues = resp.data;
          const { profit = [] } = this.chartValues;
          const filterProfit = profit.filter((item) => item._id);
          const filterProfitSort = filterProfit.sort((prev, next) => prev._id - next._id);
          if (filterProfitSort.length === 1) {
            this.addValuesInGraphics(filterProfitSort, interval, this.labelsProfit, this.dataProfit, true);
          } else if (filterProfitSort.length > 1) {
            this.addValuesInGraphics(filterProfitSort, interval, this.labelsProfit, this.dataProfit, false);
          } else {
            this.labelsProfit.push(0, 0);
            this.dataProfit.push(0, 0);
          }
          const { income = [] } = this.chartValues;
          const filterIncome = income.filter((item) => item._id);
          const filterIncomeSort = filterIncome.sort((prev, next) => prev._id - next._id);
          if (filterIncomeSort.length === 1) {
            this.addValuesInGraphics(filterIncomeSort, interval, this.labelsIncome, this.dataIncome, true);
          } else if (filterIncomeSort.length > 1) {
            this.addValuesInGraphics(filterIncomeSort, interval, this.labelsIncome, this.dataIncome, false);
          } else {
            this.labelsIncome.push(0, 0);
            this.dataIncome.push(0, 0);
          }
          if (this.$refs.incomeChart !== undefined) {
            this.$refs.incomeChart.update();
          }
          if (this.$refs.profitChart !== undefined) {
            this.$refs.profitChart.update();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    exportToExcel() {
      const {
        interval,
        start: startDate,
        end: endDate,
      } = this.filterQuery;
      const start = moment(this.toUTCOffset(startDate)).toISOString();
      const end = moment(this.toUTCOffset(endDate)).toISOString();
      const data = {
        start,
        end,
        interval,
      };
      dashboardApi.generateExcel(data)
        .then((resp) => {
          const link = this.$refs.downloadExcel;
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const filename = 'ub-report.xls';
          link.href = url;
          link.setAttribute('download', filename);
          link.click();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getOptionImage(image) {
      try {
        /* eslint-disable */
        return require(`@/assets/images/country/${image}.svg`);
        /* eslint-enable */
      } catch (error) {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../sass/variables";
  .chart {
    &-line {
      background: $color-white;
      border-radius: $borderRadius;
      margin-bottom: 20px;

      &__text {
        padding: 22px 20px 0 20px;
      }

      canvas {
        height: 140px !important;
        cursor: pointer;
      }

      &__title {
        font: $font-size-huge $font-global-medium;
        color: $color-black;
        margin-bottom: 4px;
      }

      &__value {
        color: $color-silver-chalice;
      }

      & + .chart-line {
        margin-top: 20px;
      }
    }
  }
</style>

<style scoped lang="scss">
  @import "../sass/variables";

  .chart {
    &__list {
      display: flex;
      flex-wrap: wrap;
      margin: -10px -10px 10px -10px;
    }

    &-info {
      max-height: 416px;
      display: flex;

      &__left {
        margin-right: 20px;
        background: $color-white;
        border-radius: $borderRadius;
        width: calc(50% - 10px);
        padding: 15px;
        margin-bottom: -20px;
      }

      &__map {
        max-width: 630px;
        width: 100%;
        margin: auto;
        cursor: pointer;
      }

      &__right {
        width: calc(50% - 10px);
      }
    }

    &-item {
      background: $color-white;
      border-radius: $borderRadius;
      min-height: 93px;
      max-height: 93px;
      display: flex;
      align-items: center;
      padding: 16px;
      margin: 10px;
      width: calc(25% - 20px);

      &__left {
        margin-right: 12px;
      }

      &__value {
        font: $font-size-huge $font-global-medium;
        color: $color-black;
        margin-bottom: 4px;
        max-width: 140px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &__price {
        position: relative;
        left: -5px;
      }

      &__text {
        color: $color-silver-chalice;
      }

      &__icon {
        max-width: 40px;
        min-width: 40px;
        border-radius: 50%;
        background: $color-dodger-blue;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          color: $color-white;
          font-size: $font-size-dd;
          position: relative;
        }

        &_third {
          i {
            font-size: $font-size-xlg;
            left: -2px;
          }
        }

        &_fourth {
          i {
            font-size: $font-size-h3;
            left: -2px;
          }
        }

      }
    }
  }

</style>
